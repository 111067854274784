import React, { useLayoutEffect } from "react";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react-lite";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";

class ErrorBoundaryStore {
    @observable
    status?: number;

    constructor() {
        makeObservable(this);
    }

    @action
    setStatus(status?: number) {
        this.status = status;
    }
}

const context = React.createContext(new ErrorBoundaryStore());
export const useErrorBoundary = () => React.useContext(context);

export const ErrorBoundary: React.FC = observer(({ children }) => {
    const errorBoundary = useErrorBoundary();
    const location = useLocation();

    useLayoutEffect(() => {
        errorBoundary.setStatus(undefined);
    }, [errorBoundary, location.pathname]);

    if (errorBoundary.status === 403) {
        return (
            <ErrorViewWrapper>
                <div className="error-content">
                    <h4>Not Permitted</h4>
                    <Link to={"/"} className="mt-3">
                        HOME
                    </Link>
                </div>
            </ErrorViewWrapper>
        );
    } else if (errorBoundary.status === 404) {
        return (
            <ErrorViewWrapper>
                <div className="error-content">
                    <h4>Not Found</h4>
                    <Link to={"/"} className="mt-3">
                        HOME
                    </Link>
                </div>
            </ErrorViewWrapper>
        );
    } else if (errorBoundary.status === 500) {
        return (
            <ErrorViewWrapper>
                <div className="error-content">
                    <h4>500에러 임시</h4>
                    <Link to={"/"} className="mt-3">
                        HOME
                    </Link>
                </div>
            </ErrorViewWrapper>
        );
    } else {
        return <>{children}</>;
    }
});

const ErrorViewWrapper = styled.div`
    display: flex;
    flex-shrink: 1;
    flex-grow: 0;
    overflow: hidden;
    height: 100%;
    flex-direction: column;

    .error-content {
        display: flex;
        flex-direction: column;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 20px;
        width: 60%;
        overflow: hidden;
        padding: 5rem;
    }
`;
