import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {Message} from "../../model/conversationMaker/Message";
import MessageRepository from "../../repository/conversationMaker/MessageRepository";
import {StoreState} from "../../model/common";
import {emptyPage, Page} from "../../model/pagination";
interface searchData{
    pages : number[]
    page_index: number
}
const emptySearchData : searchData = {
    pages: [],
    page_index: 0
}
class MessageStore{
    @observable
    state: StoreState;

    @observable startPage : number;
    @observable messages : Page<Message>;
    @observable searchData : searchData;

    constructor() {
        this.state = "none";
        this.startPage = 0;
        this.messages = emptyPage();
        this.searchData = emptySearchData;
        makeObservable(this);
    }

    @action
    async load(conversationId: string){
        this.state = 'pending';
        try {
            const {data} = await MessageRepository.find(conversationId, this.messages.page, this.messages.size);
            runInAction(()=>{
                this.messages = {
                    ...data.result,
                    content : [...this.messages.content, ...data.result.content]
                };
            })
            this.state = "done"
        }catch (e){
            console.error(e);
            throw e;
        }
    }

    @action
    async loadPrev(conversationId : string){
        this.startPage += 1;
        this.state = 'pending';
        try {
            const {data} = await MessageRepository.find(conversationId, this.startPage, this.messages.size);
            runInAction(()=>{
                this.messages.content = [...data.result.content, ...this.messages.content]
            })
            this.state = "done"
        }catch (e){
            console.error(e);
            throw e;
        }
    }

    @action
    async search(conversationId : string, query: string){
        this.state = 'pending';
        this.messages = emptyPage();
        try {
            const {data} = await MessageRepository.search(conversationId, query, this.messages.size);
            runInAction(()=>{
                this.searchData.pages = data.result.index;
            })
            this.state = "done"
        }catch (e){
            console.error(e);
            this.state = "error"
            throw e;
        }
    }

    @action
    nextPage(){
        this.messages.page -= 1;
    }

    @action
    nextSearch(){
        this.messages = emptyPage();
        this.searchData.page_index += 1;
        this.messages.page = this.searchData.pages[this.searchData.page_index];
        this.startPage = this.messages.page;
    }

    @action
    prevSearch(){
        this.messages = emptyPage();
        this.searchData.page_index -= 1;
        this.messages.page = this.searchData.pages[this.searchData.page_index];
        this.startPage = this.messages.page;
    }

    @computed
    get hasNextSearch() {
        return this.searchData.page_index + 1 < this.searchData.pages.length;
    }

    @computed
    get hasPrevSearch(){
        return this.searchData.page_index - 1 >= 0;
    }

    @computed
    get parsedMessage(){
        return JSON.parse(JSON.stringify(this.messages.content));
    }

    @action
    clear(){
        this.startPage = 0;
        this.messages = emptyPage();
        this.searchData = emptySearchData;
    }
}

export default MessageStore;