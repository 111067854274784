import * as views from './view';

interface routeInterface{
    path: string,
    view: any,
    exact: boolean,
    hasMenu: boolean
}

export const routes: routeInterface[] = [
    {
        path: "/user-groups",
        view: views.userGroupMainView,
        exact: false,
        hasMenu: true
    },
    {
        path: "/template/create",
        view: views.templateMainView,
        exact: true,
        hasMenu: true
    },
    {
        path: "/template/scenarioUpdate",
        view: views.updateScenarioView,
        exact: true,
        hasMenu: true
    },
    {
        path: "/channels",
        view: views.findChannelView,
        exact: true,
        hasMenu: true
    },
    {
        path: "/channels/:channelId/chat-logs",
        view: views.chattingLogView,
        exact: false,
        hasMenu: true
    },
    {
        path: "/channels/:channelId/link",
        view: views.channelLinkView,
        exact: true,
        hasMenu: false
    },
    {
        path: "/channels/payment",
        view: views.paymentHistoryView,
        exact: true,
        hasMenu: true
    },
    {
        path: "/channels/message",
        view: views.messageAccountTransactionView,
        exact: true,
        hasMenu: true
    },
    {
        path: '/skill/category',
        view : views.skillCategoryEditView,
        exact: false,
        hasMenu: true
    },
    {
        path: "/skill/skill",
        view: views.skillEditView,
        exact: false,
        hasMenu: true
    },
    {
        path: "/skill/template",
        view: views.skillMessageTemplateView,
        exact: false,
        hasMenu: true
    },
    {
        path: "/login",
        view: views.loginView,
        exact: true,
        hasMenu: false
    },
    {
        path: "/channels/:channelId",
        view: views.chattingLogView,
        exact: false,
        hasMenu: true
    },
    {
        path : "/sangdamtalks",
        view: views.sangdamtalkFindChannelView,
        exact: true,
        hasMenu: true
    },
    {
        path : "/sangdamtalks/:sangdamtalkId",
        view: views.sangdamtalkChannelDetailView,
        exact: false,
        hasMenu: true
    },
    {
        path: "/cafe24-token-history",
        view: views.cafe24TokenHistoryView,
        exact: true,
        hasMenu: true
    },
    {
        path: "/code-snippet",
        view: views.codeSnippetManageView,
        exact: true,
        hasMenu: true
    },
    {
        path: "/sender-number-manage",
        view: views.senderNumberManageView,
        exact: true,
        hasMenu: true
    },
    {
        path: "/knowledge-graph",
        view: views.findKnowledgeGraphView,
        exact: true,
        hasMenu: true
    },
    {
        path: "/knowledge-graph/:channelId",
        view: views.knowledgeGraphManageView,
        exact: false,
        hasMenu: true
    },
    {
        path: '/chatbot-editable-messages',
        view: views.chatbotEditableMessageView,
        exact: false,
        hasMenu: true
    },
    {
        path: '/search-manager/queries',
        view: views.searchManagerQueriesView,
        exact: true,
        hasMenu: true
    },
    {
        path: '/search-manager/queries/:queryId',
        view: views.searchManagerQueryTestView,
        exact: false,
        hasMenu: true
    },
    {
        path: '/search-manager/jinja/test',
        view: views.searchManagerJinjaTestView,
        exact: false,
        hasMenu: true
    },
    {
        path: "*",
        view: views.notFoundRedirectView,
        exact: true,
        hasMenu: false
    },
];

export default routes;