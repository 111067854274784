import {action, observable, makeObservable, runInAction} from "mobx";
import { Scenario } from "../../model/conversationMaker";
import ScenarioRepository from "../../repository/conversationMaker/ScenarioRepository";

class ScenarioStore {
    @observable scenarios: Scenario[];
    @observable selectedScenario?: Scenario;

    constructor() {
        this.selectedScenario = undefined;
        this.scenarios = [];
        makeObservable(this);
    }

    @action
    async load() {
        try {
            const {data} = await ScenarioRepository.readScenarios();
            runInAction(()=>{
                this.scenarios = data.result;
            })
        }catch(e){
            console.error(e);
            throw e;
        }
    }

    @action
    selectScenario(scenario: Scenario){
        this.selectedScenario = scenario;
    }

    @action
    clear() {
        this.selectedScenario = undefined;
        this.scenarios = [];
    }
}

export default ScenarioStore;
