import { action, observable, makeObservable } from "mobx";
import {ChatbotTemplate} from "../../model/chatbot";
import ChatbotTemplateRepository from "../../repository/chatbot/ChatbotTemplateRepository";
import {StoreState} from "../../model/common";

class ChatbotTemplateStore {
    @observable storeState : StoreState;
    @observable chatbotTemplates: ChatbotTemplate[];

    constructor() {
        this.storeState = "none";
        this.chatbotTemplates = [];
        makeObservable(this);
    }

    @action
    async load() {
        try {
            const {data} = await ChatbotTemplateRepository.find();
            this.chatbotTemplates = data.result
        } catch (e) {
            console.error(e)
            throw e
        }
    }


    async update(chatbotTemplateId: string, key: string, title: string, sourceId: string) {
        try {
            await ChatbotTemplateRepository.updateScenario(chatbotTemplateId, key, title, sourceId);
            await this.load();
        }catch(e){
            console.error(e);
            throw e;
        }
    }

    async delete(template_id: string) {
        try {
            await ChatbotTemplateRepository.delete(template_id);
            await this.load();
        }catch(e){
            console.error(e);
        }
    }

    @action
    clear() {
        this.chatbotTemplates = [];
    }
}

export default ChatbotTemplateStore;
