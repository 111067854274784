import {action, makeObservable, observable, runInAction} from "mobx";
import ConversationRepository from "../../repository/conversationMaker/ConversationRepository";
import {Conversation, ConversationSearchCondition} from "../../model/conversationMaker/Conversation";
import {emptyPage, Page} from "../../model/pagination";


class ConversationStore{
    @observable
    conversations : Page<Conversation>;
    @observable
    selectedConversation?: Conversation;

    constructor() {
        this.selectedConversation = undefined;
        this.conversations = emptyPage();
        makeObservable(this);
    }

    @action
    async load(channelId: string){
        try {
            const {data} = await ConversationRepository.find(channelId, this.conversations.page, this.conversations.size);
            runInAction(()=>{
                this.conversations = {
                    ...data.result,
                    content : [...this.conversations.content, ...data.result.content]
                };
            })
        }catch(e){
            console.error(e);
            throw e;
        }
    }

    @action
    async search(channelId: string, searchCondition: ConversationSearchCondition){
        try {
            const {data} = await ConversationRepository.search(channelId, searchCondition, this.conversations.page, this.conversations.size);
            runInAction(()=>{
                this.conversations = {
                    ...data.result,
                    content : [...this.conversations.content, ...data.result.content]
                };
            })
        }catch(e){
            console.error(e);
            throw e;
        }
    }

    @action
    nextConversation(){
        this.conversations.page += 1;
    }

    @action
    selectConversation(conversation: Conversation){
        this.selectedConversation = conversation;
    }

    @action
    clear(){
        this.selectedConversation = undefined;
        this.conversations = emptyPage();
    }
}

export default ConversationStore;