import React, {Suspense, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Redirect, Route, Switch, useHistory, useLocation, withRouter} from "react-router-dom";
import useStores from "./hook/useStores";
import socketStore from "./store/WebSocketStore";
import * as Layout from "@42maru/web-starter";
import * as Images from '../src/assets/images'
import routes from "./routes";
import "moment/locale/ko";
import {NotificationContainer} from "./hook/useNotification";
import {ErrorBoundary, useErrorBoundary} from "./hook/useErrorBoundary";
import {Button} from "react-bootstrap";
import Dialog from "./hook/useDialog";
import {Toaster} from "react-hot-toast";

const menuContent = [
    {
        icon: "dripicons-user-group",
        label: '사용자 그룹',
        to: "/user-groups"
    },
    {
        icon: "dripicons-print",
        label: '챗봇 템플릿',
        content: [
            {
                label: '템플릿 관리',
                to: '/template/create'
            },
            {
                label: '시나리오 및 템플릿 업데이트',
                to: "/template/scenarioUpdate"
            }
        ]
    },
    {
        icon: "dripicons-gear",
        label: '채널 관리',
        content: [
            {
                label: '채널목록',
                to: "/channels"
            },
            {
                label: '결제내역',
                to: "/channels/payment"
            },
            {
                label: '문자서비스내역',
                to: "/channels/message"
            }
        ]
    },
    {
        icon: "dripicons-rocket",
        label: '챗봇 스킬 관리',
        content: [
            {
                label: '스킬 카테고리 관리',
                to: "/skill/category"
            },
            {
                label: '스킬 관리',
                to: "/skill/skill"
            },
            {
                label: '스킬 메시지 템플릿',
                to: "/skill/template"
            },
        ]
    },
    {
        icon: 'mdi mdi-filmstrip-box',
        label: '챗봇 Editable Messages',
        to: '/chatbot-editable-messages'
    },
    {
        icon: "dripicons-headset",
        label: '상담톡',
        to: "/sangdamtalks"
    },
    {
        icon: "dripicons-time-reverse",
        label: '카페24 토큰 갱신 이력',
        to: '/cafe24-token-history'
    },
    {
        icon: "dripicons-code",
        label: '코드 스니펫 관리 (Beta)',
        to: '/code-snippet'
    },
    {
        icon: "dripicons-phone",
        label: '발신 번호 등록 관리',
        to: "/sender-number-manage"
    },
    {
        icon: "dripicons-search",
        label: '검색 매니저 관리',
        content: [
            {
                label: 'Jinja 테스트',
                to: "/search-manager/jinja/test"
            },
            {
                label: '쿼리 관리',
                to: "/search-manager/queries"
            }
        ]
    }
    // {
    //     icon: "dripicons-network-1",
    //     label: '스마트 FAQ',
    //     to: "/knowledge-graph"
    // }
];

const App: React.FC = observer(() => {
    const history = useHistory();
    const location = useLocation();
    const errorBoundary = useErrorBoundary()
    const {loginUserStore} = useStores()

    useEffect(() => {
        socketStore.connectSocket("127.0.0.1", { transports: ["websocket"]});
        return() => {
            socketStore.clear();
        }
    }, [])

    useEffect(()=>{
        (async () => {
            if (!loginUserStore.user) {
                try {
                    await loginUserStore.load();
                }catch(e){
                    if (e.response && e.response.status !== 401) {
                        errorBoundary.status = e.response.status
                    } else if (e.response && e.response.status === 401) {
                        errorBoundary.status = undefined
                        loginUserStore.clear();
                        if(location.pathname !== '/login')
                            history.push('/login');
                    }
                }
            }
        })();
    }, [location, history, loginUserStore, errorBoundary])

    const onClickLogout = () => {
        loginUserStore.logout().then(()=>{
            window.location.reload();
        })
    }

    if (location.pathname === "/") {
        return <Redirect to={"/user-groups"} />;
    }

    return (
        <ErrorBoundary>
            <Layout.Wrapper>
                <Dialog/>
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        {routes.map((route)=>(
                            <Route key={route.view} exact={route.exact} path={route.path}>
                                <>
                                    {route.hasMenu&&
                                        <Layout.SideMenu key="left-side-menu">
                                            <Layout.Brand homeUrl={"/"} logoImage={Images.logoImage} />
                                            <Layout.Menu content={menuContent} path={location.pathname}/>
                                            {loginUserStore.user &&(
                                                <div className='d-flex justify-content-center'>
                                                    <Button className='mb-2' style={{width: "70%"}} variant="danger" onClick={onClickLogout}>로그아웃</Button>
                                                </div>
                                            )}
                                        </Layout.SideMenu>
                                    }
                                    <route.view/>
                                </>
                            </Route>
                        ))}
                    </Switch>
                </Suspense>
                <NotificationContainer/>
                <Toaster position="top-center" containerStyle={{top: "14px"}}/>
            </Layout.Wrapper>
        </ErrorBoundary>
    );
});

export default withRouter(App);
