import { action, makeObservable, observable } from "mobx";
import { configure } from "mobx";

configure({
    useProxies: "never"
});

export enum AlertType {
    INFO = "info",
    SUCCESS = "success",
    WARNING = "warning",
    DANGER = "danger"
}

class AlertStore {
    @observable show: boolean = false;
    @observable message: string = "";
    @observable type: AlertType = AlertType.INFO;
    displayTimer?: any;

    constructor() {
        makeObservable(this);
    }

    @action
    open = (type: AlertType, message: string) => {
        this.show = true;
        this.message = message;
        this.type = type;

        this.displayTimer = setTimeout(() => {
            this.close();
        }, 3000);
    };

    @action
    close = () => {
        this.show = false;
        this.message = "";
        this.type = AlertType.INFO;
    };
}

export default AlertStore;
