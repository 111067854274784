import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import { configure } from "mobx";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { AXIOS_DEFAULTS_TIMEOUT } from "./core/variables";
import "./assets/global.scss";
import {RecoilRoot} from "recoil";
import {QueryClient, QueryClientProvider} from "react-query";

axios.defaults.withCredentials = true;
axios.defaults.timeout = Number(AXIOS_DEFAULTS_TIMEOUT);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        }
    }
});

configure({
    enforceActions: "never",
});

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <RecoilRoot>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </RecoilRoot>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
