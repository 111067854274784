import axios from "axios";
import {ADMIN_HOST} from "../../core/variables";

class ChannelRepository{
    async find_paging(searchKeyword: string | undefined, page: number, size: number) {
        return axios.get(`${ADMIN_HOST}/v1/chatbot/channel`, {
            params: {
                name: searchKeyword,
                page: page,
                size: size
            }
        });
    }
}

export default new ChannelRepository();