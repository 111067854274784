import {action, makeObservable, observable} from "mobx";
import {PaymentGetRequestData, PaymentTransactionWithSubscriptionHistory} from "../model/chatbotPayment";
import {StoreState} from "../model/common";
import chatbotPaymentTxRepository from "../repository/ChatbotPaymentTxRepository";


class ChatbotPaymentCardTxListStore {
    @observable
    data: PaymentTransactionWithSubscriptionHistory[];

    @observable
    state: StoreState;

    @observable
    nextState: StoreState;

    // @observable
    // query: {
    //     limit: number,
    //     offset: number
    // };
    //
    // @observable
    // hasNextMessage: boolean;

    constructor() {
        this.data = [];
        this.state = 'none';
        this.nextState = 'none';
        // this.query = {
        //     offset: 0,
        //     limit: 20
        // }
        // this.hasNextMessage = false;
        makeObservable(this);
    }

    @action
    async load(request_data: PaymentGetRequestData) {
        this.state = 'pending';
        try {
            const {data} = await chatbotPaymentTxRepository.find(request_data);
            this.data = []
            this.data = data.result;
            // this.hasNextMessage = this.data.length === this.query.limit;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    // @action
    // async next(channelId: string) {
    //     this.nextState = 'pending';
    //     this.query = {
    //         ...this.query,
    //         offset: this.query.offset + this.query.limit
    //     };
    //
    //     try {
    //         const {data} = await paymentTransactionRepository.find(channelId, {
    //             method: 'card',
    //             type: 'payment',
    //             offset: this.query.offset,
    //             limit: this.query.limit
    //         });
    //         this.data = [...this.data, ...data.result];
    //         this.hasNextMessage = data.result.length === this.query.limit;
    //         this.nextState = 'done';
    //     } catch (e) {
    //         this.nextState = 'error';
    //         throw e;
    //     }
    // }

    @action
    clear() {
        this.data = [];
        this.state = 'none';
        this.nextState = 'none';
        // this.query = {
        //     offset: 0,
        //     limit: 20
        // };
        // this.hasNextMessage = false;
    }
}


export default ChatbotPaymentCardTxListStore;