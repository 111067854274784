import {makeObservable, observable, action} from "mobx";
import {ChatbotAdminSocket} from "../util/ChatbotAdminSocket";
import { configure } from "mobx";

configure({
    useProxies: "never"
});

type WebScoketEvents =
    | "client_connected"
    | "client_disconnected"
    | "on_update"
    | "on_update_failed"
    | "finish_update"

interface EventFunctions {
    [key: string]: any[];
}

class WebSocketStore{
    static _instance: WebSocketStore;
    @observable connected: boolean = false;
    socketInitialized: boolean = false;
    socket?: ChatbotAdminSocket;
    eventFunctions: EventFunctions = {
        client_connected: [],
        client_disconnected: [],
        on_update: [],
        on_update_failed: [],
        finish_update: []
    };

    constructor() {
        makeObservable(this);
    }

    static getInstance = () => {
        if (WebSocketStore._instance == null) {
            WebSocketStore._instance = new WebSocketStore();
        }
        return WebSocketStore._instance;
    };

    @action
    connectSocket = (userIp: string, opts?: SocketIOClient.ConnectOpts) => {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = undefined;
        }

        if (!this.socket) {
            this.socket = new ChatbotAdminSocket(`${process.env.REACT_APP_SOCKET_HOST}`, `${process.env.REACT_APP_SOCKET_PATH}`,opts);
            this.socket.connect(
                {user_ip : userIp},
                () => {
                    this.connected = true;
                    console.log('WebSocket connected');
                }
            );

            this.socket.on("disconnected", () => {
                this.connected = false;
                console.log("WebSocket disconnected");
            });

            this.socket.on("on_update", (response: any)=>{
                this.eventFunctions["on_update"].forEach((func)=> func(response));
            })

            this.socket.on("on_update_failed", (response: any)=>{
                this.eventFunctions["on_update_failed"].forEach((func)=> func(response));
            })

            this.socket.on("finish_update", (response: any)=>{
                this.eventFunctions["finish_update"].forEach((func)=>func(response));
            })

            this.socketInitialized = true;
        }
    };

    sendMessage = (data: any) => {
        if (this.socket) {
            this.socket.emit("chat_consultant", data);
        }
    };

    send = (event: string, data: any) => {
        if (this.socket) {
            this.socket.emit(event, data);
        }
    };

    disconnect = () => {
        try {
            this.socket!.disconnect();
        } catch (e) {}
        this.connected = false;
    };

    addEventListener = (event: WebScoketEvents, callback: (data: any) => void) => {
        this.eventFunctions[event].push(callback);
    };

    removeEventListener = (event: WebScoketEvents, callback: (data: any) => void) => {
        const index = this.eventFunctions[event].indexOf(callback);
        if (index >= 0) {
            this.eventFunctions[event].splice(index, 1);
        }
    };

    @action
    clear = () => {
        this.disconnect();
        this.socket = undefined;
        this.socketInitialized = false;
    };
}

const store = WebSocketStore.getInstance();

export default store;