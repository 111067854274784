import React from "react";

export const updateScenarioView = React.lazy(() => import("./chatbotTemplate/ScenarioUpdateView"));
export const templateMainView = React.lazy(() => import("./chatbotTemplate/TemplateMainView"))
export const notFoundRedirectView = React.lazy(() => import("./NotFoundRedirectView"));
export const findChannelView = React.lazy(() => import("./channel/ChannelList"))
export const channelLinkView = React.lazy(() => import("./channel/IntegrationView"))
export const paymentHistoryView = React.lazy(() => import("./channel/PaymentTransactionsView"))
export const messageAccountTransactionView = React.lazy(() => import("./channel/MessageAccountTransactionView"))
export const loginView = React.lazy(() => import("./LoginView"))
export const skillEditView = React.lazy(() => import("./skill/SkillEditView"));
export const skillMessageTemplateView = React.lazy(() => import("./skill/SkillMessageTemplateView"));
export const skillCategoryEditView = React.lazy(() => import('./skill/SkillCategoryEditView'));
export const chattingLogView = React.lazy(() => import("./channel/ChannelChatLogView"));
export const sangdamtalkFindChannelView = React.lazy(() => import("./sangdamtalk/SangdamtalkListView"));
export const sangdamtalkChannelDetailView = React.lazy(() => import("./sangdamtalk/SangdamtalkDetailView"));
export const findKnowledgeGraphView = React.lazy(() => import("./knowledgeGraph/FindKnowledgeGraphView"));
export const knowledgeGraphManageView = React.lazy(() => import("./knowledgeGraph/KnowledgeGraphManageView"));
export const cafe24TokenHistoryView = React.lazy(() => import("./Cafe24TokenHistoryView"));
export const userGroupMainView = React.lazy(() => import("./userGroup/UserGroupMain"));
export const codeSnippetManageView = React.lazy(() => import("./CodeSnippetManageView"));
export const senderNumberManageView = React.lazy(() => import("./SenderNumberManageView"));
export const chatbotEditableMessageView = React.lazy(() => import("./chatbotEditableMessage/ChatbotEditableMessageView"));
export const searchManagerQueriesView = React.lazy(()=> import("./searchManager/SearchManagerQueriesView"));
export const searchManagerQueryTestView = React.lazy(()=> import("./searchManager/SearchManagerQueryTestView"));
export const searchManagerJinjaTestView = React.lazy(()=> import("./searchManager/SearchManagerJinjaTestView"));
