import {action, makeAutoObservable, observable} from "mobx";
import {StoreState} from "../model/common";
import {MessageAccountTx, MessageAccountTxPaginationPropsFilter} from "../model/messageAccountTx";
import {PaginationProps} from "../model/pagination";
import MessageAccountTxRepository from "../repository/MessageAccountTxRepository";

class MessageAccountTxStore {
    @observable
    data: MessageAccountTx[];

    @observable
    page: number;

    @observable
    count?: number;

    @observable
    state: StoreState;

    constructor() {
        this.data = [];
        this.state = 'none'
        this.page = 1
        makeAutoObservable(this)
    }

    @action
    setPage(page: number){
        this.page = page
    }


    @action
    async pagination(props: PaginationProps<MessageAccountTxPaginationPropsFilter>) {
        this.state = 'pending';
        try {
            const {data} = await MessageAccountTxRepository.find(props);
            this.data = []
            this.data = data.result;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e
        }
    }

    @action
    async getClientTotalCount(filters: MessageAccountTxPaginationPropsFilter) {
        try {
            this.page = 1 // 페이지 초기화
            const {data} = await MessageAccountTxRepository.getClientCount(filters);
            this.count = data.result
        } catch (e) {
            throw e
        }
    }

    @action
    clear() {
        this.state = 'none';
        this.data = []
    }

    @action
    clearCount() {
        this.count = undefined;
    }
}

export default MessageAccountTxStore;



