import axios from "axios";
import {ADMIN_HOST} from "../../core/variables";

class MessageRepository {
    find(conversationId : string, page: number, size: number){
        return axios.get(`${ADMIN_HOST}/v1/cm/message`,
            {
                params : {
                    conversation_id : conversationId,
                    page: page,
                    size: size
                }
            })
    }

    search(conversationId: string, query: string, size: number){
        return axios.get(`${ADMIN_HOST}/v1/cm/message/search`,
            {
                params : {
                    conversation_id : conversationId,
                    query: query,
                    size: size
                }
            })
    }
}

export default new MessageRepository();