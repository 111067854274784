import axios from "axios";
import {ADMIN_HOST} from "../core/variables";
import {PaymentGetRequestData} from "../model/chatbotPayment";

class ChatbotPaymentTxRepository {
    async find(request_data: PaymentGetRequestData) {
        return axios.get(`${ADMIN_HOST}/v1/chatbot/payment-transactions`, {
            params: {
                start_created_at: request_data.startCreatedAt,
                end_created_at: request_data.endCreatedAt,
                channel_id: request_data.channelId,
                target: request_data.target
            }
        });
    }
}

export default new ChatbotPaymentTxRepository();