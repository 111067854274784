export type Page<T> = {
    content: T[];
    page: number;
    size: number;
    total: number;
    total_pages: number;
    has_previous: boolean;
    has_next: boolean;
    is_first: boolean;
    is_last: boolean;
};

export const emptyPage = <T>() => ({
    content: new Array<T>(),
    page: 0,
    size: 10,
    total: 0,
    total_pages: 0,
    has_previous: false,
    has_next : false,
    is_first: false,
    is_last: false
});

export type PaginationProps<T> = {
    filters: T
    offset: number
    limit: number
}

export type PageWithParams<T, E> = {
    page: T[]
    pageParams: {
        filters: E
        offset: number
        limit: number
        isLast: boolean
    }
}

export const TABLE_ROW_COUNT = 10
