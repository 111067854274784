import axios from "axios";
import {ADMIN_HOST} from "../../core/variables";
import {ChatbotTemplateForm} from "../../model/chatbot";

class ChatbotTemplateRepository{
    async create(body: ChatbotTemplateForm) {
        let form = new FormData();
        for (const [key, value] of Object.entries(body)){
            form.append(key, value === undefined ? null : value)
        }

        return axios.post(`${ADMIN_HOST}/v1/chatbot/templates`, form, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    }

    async find() {
        return axios.get(`${ADMIN_HOST}/v1/chatbot/templates`);
    }

    async update(chatbotTemplateId: string, body: ChatbotTemplateForm){
        let form = new FormData();
        for (const [key, value] of Object.entries(body)){
            form.append(key, value === undefined ? null : value)
        }

        return axios.patch(`${ADMIN_HOST}/v1/chatbot/templates/${chatbotTemplateId}`, form, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    }

    async updateScenario(chatbotTemplateId: string, key: string, title: string, sourceId: string) {
        return axios.put(`${ADMIN_HOST}/v1/chatbot/templates/${chatbotTemplateId}/scenario`, {
            key: key,
            title: title,
            source_id: sourceId
        });
    }

    async delete(chatbot_template_id: string) {
        return axios.delete(`${ADMIN_HOST}/v1/chatbot/templates/${chatbot_template_id}`);
    }

    async readUserAttributes(templateKey: string) {
        return axios.get(`${ADMIN_HOST}/v1/chatbot/templates/user-attributes`, {params: {templateKey}});
    }

    async uploadFile(file: File) {
        let form = new FormData();
        form.append('file', file);
        return axios.post(`${ADMIN_HOST}/v1/chatbot/templates/upload-file`, form);
    }
}

export default new ChatbotTemplateRepository();