import axios from "axios";
import {ADMIN_HOST} from "../../core/variables";

class ScenarioTemplateRepository{
    async createScenarioTemplate(scenario_id: string, name: string) {
        return axios.post(`${ADMIN_HOST}/v1/cm/scenario_template/scenario/${scenario_id}`, { name });
    }

    async readScenarioTemplates() {
        return axios.get(`${ADMIN_HOST}/v1/cm/scenario_template`);
    }

    async deleteScenarioTemplate(template_id: string) {
        return axios.delete(`${ADMIN_HOST}/v1/cm/scenario_template/${template_id}`);
    }

    async updateScenarioTemplate(template_id: string, scenario_id: string, scenarioUpdateKeys: string[]) {
        return axios.put(`${ADMIN_HOST}/v1/cm/scenario_template/${template_id}/scenario/${scenario_id}`,
            { keys: scenarioUpdateKeys }
        );
    }
}

export default new ScenarioTemplateRepository();