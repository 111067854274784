import {action, observable, makeObservable, runInAction} from "mobx";
import { ScenarioTemplate } from "../../model/conversationMaker";
import ScenarioTemplateRepository from "../../repository/conversationMaker/ScenarioTemplateRepository";

class ScenarioTemplateStore {
    @observable scenarioTemplates: ScenarioTemplate[];
    @observable selectedScenarioTemplate?: ScenarioTemplate;
    @observable state: 'none' | 'done' | 'error' = 'none';

    constructor() {
        this.selectedScenarioTemplate = undefined;
        this.scenarioTemplates = [];
        makeObservable(this);
    }

    @action
    async load() {
        try{
            const {data} = await ScenarioTemplateRepository.readScenarioTemplates();
            runInAction(()=>{
               this.scenarioTemplates = data.result;
            });
            this.state = 'done'
        }catch(e){
            console.error(e);
            this.state = 'error'
            throw e;
        }
    }

    async create(scenario_id: string, name: string) {
        try {
            await ScenarioTemplateRepository.createScenarioTemplate(scenario_id, name);
            await this.load();
        }catch(e) {
            console.error(e);
            throw e;
        }
    }

    async update(template_id: string, scenario_id: string, scenarioUpdateKeys: string[]) {
        try {
            await ScenarioTemplateRepository.updateScenarioTemplate(template_id, scenario_id, scenarioUpdateKeys);
        }catch(e) {
            console.error(e);
            throw e;
        }
    }

    async delete(template_id: string) {
        try{
            await ScenarioTemplateRepository.deleteScenarioTemplate(template_id);
            await this.load();
        }catch (e) {
            console.error(e);
            throw e;
        }
    }

    @action
    selectScenarioTemplate(scenarioTemplate : ScenarioTemplate){
        this.selectedScenarioTemplate = scenarioTemplate;
    }

    @action
    clear() {
        this.selectedScenarioTemplate = undefined;
        this.scenarioTemplates = [];
        this.state = 'none'
    }
}

export default ScenarioTemplateStore;
