import axios from "axios";
import {ADMIN_HOST} from "../core/variables";
import {ChannelProps} from "../model/chatbotChannel";

export type ChargePointBody = {
    name: string
    amount: number
    dateAfter: number
}

class ChatbotChannelRepository {
    async find(request_data: ChannelProps) {
        return axios.get(`${ADMIN_HOST}/v1/chatbot/channel/mau`, {
            params: {
                // merchant_id: request_data.merchantId,
                channel_id: request_data.channelId,
                name: request_data.name,
                template_key: request_data.templateKey,
                subscription_plan: request_data.subscriptionPlan,
                mau_option: request_data.mauOption,
                start_date: request_data.startDate,
                end_date: request_data.endDate
            }
        });
    }

    async chargePoint(channelId: string, body: ChargePointBody) {
        return axios.post(`${ADMIN_HOST}/v1/chatbot/point/${channelId}`, {body})
    }

    async read(channelId: string) {
        return axios.get(`${ADMIN_HOST}/v1/chatbot/channel/${channelId}`)
    }

    async getDownloadExcel(){
        return axios.get(`${ADMIN_HOST}/v1/chatbot/mau/download/excel`, {
            responseType: "blob",
            headers: { "Cache-Control": "no-cache" }
        });
    }
}

export default new ChatbotChannelRepository();