import axios from "axios";
import {ADMIN_HOST} from "../../core/variables";
import {ConversationSearchCondition} from "../../model/conversationMaker/Conversation";

class ConversationRepository{
    find(channelId: string, page: number, size: number){
        return axios.get(`${ADMIN_HOST}/v1/cm/conversation`,{
                params: {
                    channel_id : channelId,
                    page: page,
                    size: size
                }
            })
    }

    search(channelId: string, searchCondition: ConversationSearchCondition, page: number, size: number){
        return axios.get(`${ADMIN_HOST}/v1/cm/conversation`,
            {
                params: {
                    channel_id: channelId,
                    page: page,
                    size: size,
                    ...searchCondition
                }
            })
    }
}

export default new ConversationRepository();