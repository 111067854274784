import {action, makeObservable, observable} from "mobx";
import {StoreState} from "../model/common";
import {ChannelListItem, ChannelProps} from "../model/chatbotChannel"
import chatbotChannelRepository from "../repository/ChatbotChannelRepository"
import moment from "moment";

class ChatbotChannelListStore {
    @observable
    data: ChannelListItem[];

    @observable
    state: StoreState;

    constructor() {
        this.state = 'none';
        this.data = [];
        makeObservable(this);
    }

    @action
    async load(props: ChannelProps) {
        this.state = 'pending';
        try {
            const {data} = await chatbotChannelRepository.find(props);
            this.data = []
            this.data = data.result;

            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = [];
        this.state = 'none';
    }
}

export const getDefaultStartDate = () => {
    return moment().subtract(7, "d").startOf("day").toDate();
};

export const getDefaultEndDate = () => {
    return moment().toDate();
};

export default ChatbotChannelListStore;