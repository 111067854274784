import {action, makeObservable, observable, runInAction} from "mobx";
import { LoginUser } from "../model/user";
import { StoreState } from "../model/common";
import {getLoginUser, logout, login} from "../api/loginUserApi";

class LoginUserStore {
    @observable
    user?: LoginUser;
    @observable
    state: StoreState;

    constructor() {
        this.state = "none";
        makeObservable(this);
    }

    @action
    async load() {
        this.state = "pending";
        try {
            const response = await getLoginUser();
            runInAction(()=> {
                this.user = response.result;
            })
            this.state = "done";
        } catch (e) {
            this.state = "error";
            throw e;
        }
    }

    @action
    login = async (user_email: string, user_password: string) => {
        const response = await login(user_email, user_password);

        return response?.result.login_succeed;
    };

    @action
    logout = async () => {
        try {
            await logout();
            this.clear();
        } catch (e) {
            return await Promise.reject(e);
        }
    };

    @action
    clear() {
        this.user = undefined;
        this.state = "none";
    }
}

export default LoginUserStore;
