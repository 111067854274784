import {Scenario} from "../model/conversationMaker";
import {action, computed, makeObservable, observable} from "mobx";

interface UpdateState{
    scenario_count: number
    succeed_scenarios: Scenario[]
    failed_scenario?: Scenario
    failed_reason?: string
}
const emptyUpdateState : UpdateState = {
    scenario_count: 0,
    succeed_scenarios: [],
    failed_scenario: undefined,
    failed_reason: undefined
}

class UpdateStore {
    @observable
    updateState: UpdateState;
    @observable
    startTime: string;
    @observable
    endTime: string;

    constructor() {
        this.updateState = emptyUpdateState;
        this.startTime = "";
        this.endTime = "";
        makeObservable(this);
    }

    @action
    onSucceedUpdate = (response:any)=> {
        this.updateState.succeed_scenarios = response.scenarios;
    }

    @action
    onFailedUpdate = (response:any)=> {
        this.updateState.failed_scenario = response.scenario;
        this.updateState.failed_reason = response.error_log;
    }

    @action
    finishUpdate = ()=>{
        this.endTime = new Date().toLocaleString();
    }

    @action
    newUpdateStart = (scenarioCount : number) => {
        this.updateState = {
            ...emptyUpdateState,
            scenario_count: scenarioCount,
        };
        this.startTime = new Date().toLocaleString();
        this.endTime = "";
    }

    @computed get succedCount() {
        return this.updateState.succeed_scenarios.length;
    }

    @computed get getUpdatePercent() {
        return Math.round(this.succedCount/this.updateState.scenario_count * 100);
    }

    @action
    clear(){
        this.updateState = emptyUpdateState;
        this.startTime = "";
        this.endTime = "";
    }
}
export default UpdateStore;