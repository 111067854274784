import {action, makeObservable, observable, runInAction} from "mobx"
import {StoreState} from "../../model/common";
import {Channel} from "../../model/chatbot/Channel";
import ChannelRepository from "../../repository/chatbot/ChannelRepository";
import {Page, emptyPage} from "../../model/pagination";


class ChannelStore{
    @observable
    state: StoreState;
    @observable
    channelPage: Page<Channel>;
    @observable
    selectedChannel?: Channel;

    constructor() {
        this.state = 'none';
        this.channelPage = emptyPage();
        this.selectedChannel = undefined;
        makeObservable(this);
    }

    @action
    async load(searchKeyword: string | undefined){
        this.state = 'pending';
        try{
            const {data} = await ChannelRepository.find_paging(searchKeyword, this.channelPage.page, this.channelPage.size);
            runInAction(()=>{
                this.channelPage = {
                    ...data.result,
                    content: [...this.channelPage.content, ...data.result.content]
                };
            })
            this.state = "done"
        }catch (e){
            this.state = 'error';
            console.error(e);
            throw e;
        }
    }

    @action
    selectChannel(channel: Channel){
        this.selectedChannel = channel;
    }

    @action
    nextChannelPage(){
        this.channelPage.page += 1;
    }

    @action
    clear(){
        this.channelPage = emptyPage();
        this.selectedChannel = undefined;
    }
}

export default ChannelStore;