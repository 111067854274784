import axios from "axios";
import {ADMIN_HOST} from "../core/variables";
import {PaginationProps} from "../model/pagination";
import {MessageAccountTxPaginationPropsFilter} from "../model/messageAccountTx";

class MessageAccountTxRepository {
    async find(
        props : PaginationProps<MessageAccountTxPaginationPropsFilter>
    ) {
        return axios.get(`${ADMIN_HOST}/v1/chatbot/message`, {
            params: {
                start_created_at: props.filters.startCreatedAt,
                end_created_at: props.filters.endCreatedAt,
                // merchant_id: props.filters.merchantId,
                channel_id: props.filters.channelId,
                select_type: props.filters.selectType,
                deposit_type: props.filters.depositType,
                withdraw_type: props.filters.withdrawType,
                offset: props.offset,
                limit: props.limit
            }
        });
    }

    async getClientCount(filters : MessageAccountTxPaginationPropsFilter){
        return axios.get(`${ADMIN_HOST}/v1/chatbot/message/count`, {
            params: {
                start_created_at: filters.startCreatedAt,
                end_created_at: filters.endCreatedAt,
                channel_id: filters.channelId,
                // merchant_id: filters.merchantId,
                select_type: filters.selectType,
                deposit_type: filters.depositType,
                withdraw_type: filters.withdrawType,
            }
        });
    }

    async getDownloadExcel(filters: MessageAccountTxPaginationPropsFilter){
        return axios.get(`${ADMIN_HOST}/v1/chatbot/message/download/excel`, {
            params: {
                ...filters
            },
            responseType: "blob",
            headers: { "Cache-Control": "no-cache" }
        });
    }
}

export default new MessageAccountTxRepository();