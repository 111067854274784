import React from "react";
import LoginUserStore from "./LoginUserStore";
import ScenarioStore from "./conversationMaker/ScenarioStore";
import ScenarioTemplateStore from "./conversationMaker/ScenarioTemplateStore";
import ChatbotTemplateStore from "./chatbot/ChatbotTemplateStore";
import UpdateStore from "./UpdateStore";
import AlertStore from "./AlertStore";
import ChatbotChannelListStore from "./ChatbotChannelListStore";
import ChatbotPaymentTxStore from "./ChatbotPaymentTxStore";
import I18nStore from "./I18nStore";
import MessageAccountTxStore from "./MessageAccountTxStore";
import ChannelStore from "./chatbot/ChannelStore";
import MessageStore from "./conversationMaker/MessageStore";
import ConversationStore from "./conversationMaker/ConversationStore";

export const storeContext = React.createContext({
    i18nStore: new I18nStore(),
    loginUserStore: new LoginUserStore(),
    scenarioStore: new ScenarioStore(),
    scenarioTemplateStore: new ScenarioTemplateStore(),
    chatbotTemplateStore: new ChatbotTemplateStore(),
    updateStore: new UpdateStore(),
    alertStore: new AlertStore(),
    channelStore: new ChannelStore(),
    chatbotChannelListStore: new ChatbotChannelListStore(),
    chatbotPaymentTxStore: new ChatbotPaymentTxStore(),
    messageAccountTxStore: new MessageAccountTxStore(),
    messageStore: new MessageStore(),
    conversationStore: new ConversationStore()
});
