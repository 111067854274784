import React, {useLayoutEffect, useMemo, useRef, useState} from "react";
import {action, makeObservable, observable} from "mobx";
import styled from "styled-components";
import {observer} from "mobx-react-lite";
import {v4 as uuid} from "uuid";
import IconButton from "../component/IconButton";

const MAX_NOTIFICATION_COUNT = 5;

class NotificationStore {
    @observable
    data: {[id: string]: NotificationInfo} = {};

    constructor() {
        makeObservable(this);
    }

    @action
    success(title: string, description?: string) {
        this.addNotificationInfo('success', title, description);
    }

    @action
    warning(title: string, description?: string) {
        this.addNotificationInfo('warning', title, description);
    }

    @action
    error(title: string, description?: string) {
        this.addNotificationInfo('danger', title, description);
    }

    // @action
    // responseError(error: any, description?: string) {
    //     const title = error.response?.data?.error_message || '에러가 발생했습니다.';
    //     this.addNotificationInfo('danger', title, description);
    // }

    @action
    off(id: string) {
        const {[id]: notificationInfo, ...without} = this.data;
        this.data = without;
    }

    timeout(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    addNotificationInfo(type: NotificationType, title?: string, description?: string) {
        const notificationInfos = Object.values(this.data).filter(notificationInfo => !notificationInfo.hidden);
        if (notificationInfos.length >= MAX_NOTIFICATION_COUNT) {
            const earliestNotificationInfo = notificationInfos[0];
            this.data = {
                ...this.data,
                [earliestNotificationInfo.id]: {
                    ...earliestNotificationInfo,
                    hidden: true
                }
            }
        }

        const id = uuid();
        this.data = {
            ...this.data,
            [id]: {
                id: id,
                type: type,
                title: title,
                description: description,
                hidden: false
            }
        }
    }
}

type NotificationType = 'success'|'warning'|'danger';

type NotificationInfo = {
    id: string;
    type: NotificationType;
    title?: string;
    description?: string;
    hidden: boolean;
};

const context = React.createContext(new NotificationStore());
const useNotification = () => React.useContext(context);


export const NotificationContainer: React.FC = observer(() => {
    const notification = useNotification();
    return (
        <>
            {Object.keys(notification.data).reverse().map((id, index) => <Notification key={id} id={id} index={index} />)}
        </>
    );
});


export const Notification: React.FC<NotificationProps> = observer(({id, index}) => {
    const notification = useNotification();

    const [on, setOn] = useState(false);

    const closeTimeoutIdRef = useRef<any>(null);
    const offTimeoutIdRef = useRef<any>(null);

    useLayoutEffect(() => {
        setTimeout(() => {
            setOn(true);
            closeTimeoutIdRef.current = setTimeout(() => {
                setOn(false);
                offTimeoutIdRef.current = setTimeout(() => {
                    notification.off(id);
                }, 305);
            }, 5000);
        }, 50);

    }, [notification, id]);

    const notificationInfo = useMemo(() => {
        return notification.data[id]
    }, [notification.data, id]);

    const onClickClose = (e: React.MouseEvent) => {
        e.preventDefault();

        if (closeTimeoutIdRef.current) {
            clearTimeout(closeTimeoutIdRef.current);
        }
        if (offTimeoutIdRef.current) {
            clearTimeout(offTimeoutIdRef.current);
        }
        notification.off(id);
    }

    if (notificationInfo) {
        return (
            <NotificationStyle isOn={on} index={index} type={notification.data[id].type} hidden={notification.data[id].hidden}>
                <div className="status-notification-content">
                    {notificationInfo.type === 'success' && <i className="dripicons-checkmark text-success" />}
                    {notificationInfo.type === 'warning' && <i className="dripicons-warning text-warning" />}
                    {notificationInfo.type === 'danger' && <i className="dripicons-wrong text-danger" />}
                    <div className="d-flex flex-column ml-2 flex-grow-1">
                        <p className="p-0 mb-0">
                            {notificationInfo.title || ''}
                        </p>
                        <small>{notificationInfo.description}</small>
                    </div>
                    <IconButton className="ml-1" onClick={onClickClose}>
                        <i className="mdi mdi-close font-18 status-color" />
                    </IconButton>
                </div>
            </NotificationStyle>
        );
    }
    else {
        return null;
    }
});

type NotificationProps = {
    id: string;
    index?: number;
};

type NotificationStyleProps = {
    isOn: boolean;
    type: string;
    index?: number;
    hidden: boolean;
};

const NotificationStyle = styled.div<NotificationStyleProps>`
  position: fixed;
  -webkit-box-shadow: 0 14px 28px rgba(154,161,171,0.25), 0 10px 10px rgba(154,161,171,0.22);
  box-shadow: 0 14px 28px rgba(154,161,171,0.25), 0 10px 10px rgba(154,161,171,0.22);
  border-radius: 7px;
  transition: all .3s ease-out;
  min-width: 240px;
  z-index: 2000;

  ${props => {
    if (props.type === 'success') {
      return `color: #226d52; background-color: #d9f6eb; border: 1px solid #caf2e4;`;
    }
    else if (props.type === 'warning') {
      return `color: #816207; background-color: #fef2cf; border: 1px solid #fdecbb;`;
    }
    else if (props.type === 'danger') {
      return `color: #823636; background-color: #fee1e1; border: 1px solid #fed4d4;`;
    }
  }}

  ${props => props.index ? `top: ${40 + (65 * props.index)}px;`: `top: 40px;`}

  div.status-notification-content {
    display: flex;
    align-items: center !important;
    height: 52px;
    padding: 0.75rem 0.75rem 0.75rem 1rem;

    p {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    small {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    i.status-color {
      ${props => {
        if (props.type === 'success') {
          return 'color: #226d52;';
        }
        else if (props.type === 'warning') {
          return 'color: #816207;';
        }
        else if (props.type === 'danger') {
          return 'color: #823636;';
        }
      }}
    }
  }

  ${props => props.isOn ? `right: 20px;` : `right: -260px`}
  ${props => props.hidden && 'display: none;'}
`;


export default useNotification;