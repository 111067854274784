import {action, makeAutoObservable, observable} from "mobx";
import React from "react";
import {Button, Modal} from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import classNames from "classnames";

class DialogStore {

    @observable
    isOpen: boolean = false;

    @observable
    title: string | null = null;

    @observable
    content: string | null = null;

    @observable
    variant: DialogVariant = 'primary';

    @observable
    confirmText: string | null = null;

    @observable
    cancelText: string | null = null;

    @observable
    onConfirm: VoidFunction | null = null;

    @observable
    onCancel: VoidFunction | null = null;

    @observable
    hideConfirm: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    @action
    open(params: OpenParams) {
        this.isOpen = true;
        this.content = params.content;
        this.onConfirm = params.onConfirm || null;
        this.title = params.title || 'Alert';
        this.variant = params.variant || 'primary';
        this.confirmText = params.confirmText || 'OK';
        this.cancelText = params.cancelText || 'Cancel';
        this.onCancel = params.onCancel || null;
        this.hideConfirm = params.hideConfirm || false;
    }

    @action
    close() {
        this.isOpen = false;
        this.content = null;
        this.onConfirm = null;
        this.title = null;
        this.variant = 'primary';
        this.confirmText = null;
        this.cancelText = null;
        this.onCancel = null;
        this.hideConfirm = false;
    }
}

type DialogVariant = 'primary' | 'info' | 'success' | 'warning' | 'danger';

type OpenParams = {content: string, onConfirm?: VoidFunction, title?: string, variant?: DialogVariant, confirmText?: string, cancelText?: string, onCancel?: VoidFunction, hideConfirm?: boolean};

const context = React.createContext(new DialogStore());
export const useDialog = () => React.useContext(context);

const Dialog: React.FC = observer(() => {

    const dialog = useDialog();

    const handleClose = () => {
        if (dialog.onCancel) {
            dialog.onCancel();
        }

        dialog.close();
    };

    const onClickConfirmBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        if (dialog.onConfirm) {
            dialog.onConfirm();
        }

        dialog.close();
    };

    return (
        <Modal show={dialog.isOpen} onHide={handleClose} animation={true} centered>
            <Modal.Header className={`modal-colored-header bg-${dialog.variant}`} closeButton>
                <Modal.Title as={'h4'}>{dialog.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{whiteSpace: 'pre-wrap'}}>{dialog.content}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className={classNames({'d-none':dialog.hideConfirm} )} variant={dialog.variant} onClick={onClickConfirmBtn}>{dialog.confirmText}</Button>
                <Button variant={'outline-secondary'} onClick={handleClose}>{dialog.cancelText}</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default Dialog;