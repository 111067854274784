import axios from "axios";

axios.defaults.withCredentials = true;

export const getLoginUser = () => {
    return axios
        .get(`${process.env.REACT_APP_ADMIN_HOST}/v1/auth/me`)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const login = (user_email: string, user_password: string) => {
    return axios
        .post(`${process.env.REACT_APP_ADMIN_HOST}/v1/auth/login`,
            {user_email: user_email,
                user_password: user_password})
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const logout = () => {
    return axios
        .post(`${process.env.REACT_APP_ADMIN_HOST}/v1/auth/logout`)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => Promise.reject(error));
};